<template>
	<welcome @entered="onEntered" />
	<div class="logoArea" :style="logosAreaCSS">
		<card ref="cards" v-for="card in cards" :dir="card.dir" :long="card.title.length>5">
			<div class="title">{{card.title}}</div>
			<div class="list" v-if="!!card.list && !!card.list.length">
				<div v-for="item in card.list" class="line">{{item}}</div>
			</div>
			<div class="logos" v-if="(!!card.logos && !!card.logos.length || !!card.smallLogos && !!card.smallLogos.length) || !!card.desc" :style="logoAreaCSS">
				<div class="inner_frame" :style="logoAreaCSS">
					<img v-for="img in card.logos" class="logo" :src="img">
					<img v-for="img in card.smallLogos" class="logo small" :src="img">
					<div v-if="!!card.desc" class="logo">{{ card.desc }}</div>
				</div>
			</div>
		</card>
	</div>
	<div class="upper" :stage="stage" @click="onNext">
		<i class="fa fa-angle-up fa-bounce"></i>
	</div>
</template>

<style scoped>
.logoArea {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	perspective-origin: 50% 20%;
	font-family: "Quantum";
}
.upper {
	position: absolute;
	bottom: -100px;
	left: calc(50% - 50px);
	width: 100px;
	font-size: 50px;
	color: rgba(186, 204, 217, 1.0);
	transition: all 500ms ease-in-out;
	cursor: pointer;
}
.upper[stage="1"] {
	bottom: 20px;
}
</style>

<script>
import Welcome from '@/components/company/welcome.vue';
import Card from '@/components/company/card.vue';

const PageBottomPadding = 50;

export default {
	name: 'Company',
	components: {Welcome, Card},
	data () {
		return {
			stage: 0,
			page: 0,
			cards: [],
			isLeft: true,
			isRunning: false,
			logoAreaCSS: {},
			logosAreaCSS: {},
		}
	},
	async mounted () {
		loadFont('Quantum', 'quantum.otf', 'opentype');
		window.addEventListener('resize', this.onResize);

		this.cards.push({
			title: 'SAI',
			dir: 'left',
			list: [
				'Theory\nMath · Logic · Physics · Neuroscience · Quantum',
				'Framework\nLarge Scale AI Prover',
				'Model\nOPML · zkML · zkLLM',
				'Data',
				'Apps',
			],
			logos: [
				staticHost + '/images/allies/Deepmind.png',
				staticHost + '/images/allies/OpenAI.png',
				staticHost + '/images/allies/MIT.png',
				staticHost + '/images/allies/Berkeley.png',
				staticHost + '/images/allies/Oxford.png',
			]
		});
		this.cards.push({
			title: 'DAO',
			dir: 'right',
			list: [
				'Collaborating Platform',
				'AI Governance Library',
				'Rewarding Mechanism',
				'Safety Market',
			],
			logos: [
				staticHost + '/images/allies/ETH.png',
				staticHost + '/images/allies/Zcash.png',
				staticHost + '/images/allies/CortexAI.png',
			]
		});
		this.cards.push({
			title: 'Infra',
			dir: 'left',
			list: [
				'Distributed Compute (GPUs)',
				'Efficient Storage Cluster (SSDs)',
				'Prover Stations',
			],
			smallLogos: [
				staticHost + '/images/allies/NVidia.png',
				staticHost + '/images/allies/AMD.png',
				staticHost + '/images/allies/Bitmain.png',
			]
		});

		this.cards.push({
			title: 'Milestone 1',
			dir: 'right',
			desc: 'Open Superalignment\n2 Years',
		});
		this.cards.push({
			title: 'Milestone 2',
			dir: 'left',
			desc: 'Provably Safe AGI\n2 Years',
		});
		this.cards.push({
			title: 'Milestone 3',
			dir: 'right',
			desc: 'Age of Abundance\n4 Years',
		});

		await wait();
		this.setCardPositions();
	},
	beforeUnmount () {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize () {
			if (!!this._tmrResize) {
				clearTimeout(this._tmrResize);
			}
			this._tmrResize = setTimeout(this.handleResize, 200);
		},
		handleResize () {
			this.setCardPositions(false);
		},
		async onEntered () {
			var tasks = [];
			this.isRunning = true;

			this.page = 0;
			this.$refs.cards.forEach((card, i) => {
				if (i >= 3) return;
				tasks.push(card.enterStage());
			});

			await Promise.all(tasks);
			this.isRunning = false;
			this.stage = 1;
		},
		setCardPositions (changeDir=true) {
			var height = window.innerHeight * 0.7 - PageBottomPadding;
			var step = height / 3;
			this.logosAreaCSS.perspective = (500 / 963 * window.innerHeight) + 'px';
			this.logoAreaCSS.width = Math.ceil(window.innerWidth * 0.45) + 'px';

			this.$refs.cards.forEach((card, i) => {
				var idx = i;
				if (idx >= 3) idx -= 3;
				var bottom = height - step * (idx + 1) + PageBottomPadding;
				var offset = 20 + 60 / 3 * idx;
				if (changeDir) {
					card.setPosition(bottom, offset, this.isLeft);
					this.isLeft = !this.isLeft;
				}
				else {
					card.setPosition(bottom, offset, card.isLeft);
				}
			});
		},
		async onNext () {
			if (this.isRunning) return;

			var tasks = [];
			this.isRunning = true;

			this.$refs.cards.forEach((card, i) => {
				if (this.page === 0) {
					if (i < 3) tasks.push(card.exitStage());
					else tasks.push(card.enterStage());
				}
				else {
					if (i < 3) tasks.push(card.enterStage());
					else tasks.push(card.exitStage());
				}
			});

			await Promise.all(tasks);
			this.page ++;
			if (this.page === 2) this.page = 0;
			this.isRunning = false;
		}
	},
};
</script>