<template>
	<div class="block" :style="css">
	</div>
</template>

<style scoped>
.block {
	position: absolute;
	background-image: linear-gradient(135deg, rgba(30, 19, 29, 1.0), rgba(31, 32, 64, 1.0));
	border: 1px solid rgb(0, 89, 255);
	border-image: linear-gradient(45deg, rgba(46, 49, 124, 1.0), rgba(43, 18, 22, 1.0)) 1;
	transform: translateZ(1px);
	transform-origin: center;
	transition: all 1500ms ease-in-out;
}
</style>

<script>
const Depth = 100;

export default {
	name: 'Block',
	props: ["info"],
	data () {
		return {
			css: {},
			duration: 1500,
			running: false,
		}
	},
	async mounted () {
		var css = {};
		css.width = this.info.size + 'px';
		css.height = this.info.size + 'px';
		css.left = this.info.x + 'px';
		css.top = this.info.y + 'px';
		this.css = css;

		this.running = true;
		this.changePosition();
		await wait();
		this.animating();
	},
	methods: {
		changePosition (out=false) {
			var depth = Math.round((Math.random() * 2 - 1) * Depth);
			var duration = Math.round(Math.random() * 1600 + 800);
			if (out) {
				let half = window.innerHeight / 2;
				let hw = window.innerWidth / 2;
				depth += 500 * (Math.abs(this.info.y - half) / half);
				if (this.info.y > half) {
					this.css.top = (this.info.y + 3 * this.info.size * (Math.random() + Math.random() - 1)) + 'px';
					if (this.info.x > hw) {
						this.css.left = (window.innerWidth + Math.random() * 200) + 'px';
					}
					else {
						this.css.left = (0 - this.info.size - Math.random() * 200) + 'px';
					}
					this.css.transform = `translateZ(${depth}px) rotateX(-90deg)`;
				}
				else {
					this.css.top = (0 - this.info.size - Math.random() * 200) + 'px';
					this.css.left = (this.info.x + 3 * this.info.size * (Math.random() + Math.random() - 1)) + 'px';
					this.css.transform = `translateZ(${depth}px) rotateX(90deg)`;
				}
				this.css.zIndex = depth + 100;
			}
			else {
				this.css.transform = `translateZ(${depth}px)`;
				this.css.zIndex = depth + 100;
				this.css.transition = `all ${duration}ms ease-in-out`;
				this.duration = duration;
			}
		},
		async animating () {
			while (this.running) {
				if (!this.running) break;
				this.changePosition();
				await wait(this.duration);
				if (!this.running) break;
			}
		},
		async splitOut () {
			this.running = false;
			this.css.transition = "all 1000ms ease-in";
			await wait(50);
			this.changePosition(true);
			await wait(1000);
			this.css.opacity = 0;
			await wait(1000);
			this.css.display = 'none';
		}
	},
}
</script>