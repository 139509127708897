<template>
	<div class="word" :stage="stage" :style="style" :text="word">{{ word }}</div>
</template>

<style scoped>
.word {
	position: absolute;
	top: 50%;
	transform-origin: 50% 80%;
	transform: translateY(-50%);
	font-family: "Quantum";
	font-weight: bolder;
	color: transparent;
	-webkit-text-fill-color: transparent;
	transition: all 1000ms ease-in;
	user-select: none;
}
.word[stage="0"] {
	transform: translateY(50%) translateZ(-1000px) rotateX(-90deg);
	opacity: 0;
}
.word[stage="1"] {
	transform: translateY(-50%) translateZ(1px) rotateX(0deg);
	opacity: 1;
}
.word[stage="2"] {
	transform: translateY(-50%) translateZ(1px) rotateX(30deg) scaleY(1.8);
	opacity: 1;
}
.word[stage="3"], .word[stage="4"] {
	transform: translateY(-50%) translateZ(1px) rotateX(50deg) scaleY(3.0);
	opacity: 1;
}
.word::after {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
	background-image: linear-gradient(135deg, rgba(20, 9, 19, 1.0), rgba(21, 22, 54, 1.0));
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}
.word::before {
	content: attr(text);
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
	color: transparent;
	-webkit-text-fill-color: transparent;
	text-shadow: 0px 0px 2px rgba(255, 147, 41, 0.6), 0px 0px 2px rgba(255, 213, 45, 0.5), 0px 0px 1px rgba(255, 255, 255, 0.99), 4px 4px 8px rgba(41, 0, 41, 0.6), 3px 3px 10px rgba(25, 25, 250, 0.6);
}
.word[stage="4"]::before {
	text-shadow: 0px 0px 2px rgba(255, 208, 0, 0.9), 0px 0px 2px rgba(255, 204, 0, 0.75), 0px 0px 1px rgba(255, 255, 255, 0.99), 4px 4px 8px rgba(41, 0, 41, 0.6), 3px 3px 10px rgba(25, 25, 250, 0.6);
	transition: all 300ms ease-in-out;
}
</style>

<script>
export default {
	name: 'Word',
	props: ["word", "width", "left", "size"],
	data () {
		return {
			stage: 0,
			style: {}
		}
	},
	mounted () {
		this.style.width = this.width + 'px';
		this.style.left = this.left + 'px';
		this.style.fontSize = this.size + 'px';
	},
	methods: {
		fadeIn () {
			this.stage = 1;
		},
		async fadeOut (css) {
			this.style.transition = "all 1000ms ease-in-out, transform 250ms ease-in";
			this.style.width = css.width + 'px';
			this.style.top = css.top + 'px';
			this.style.left = css.left + 'px';
			this.style.fontSize = css.size + 'px';

			await wait(500);
			this.stage = 2;
			await wait(250);
			this.stage = 3;
			await wait(1000);
			this.stage = 4;
		},
	},
}
</script>