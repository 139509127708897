<template>
	<div class="welcome">
		<div class="background" :style="bgCSS"></div>
		<block ref="blocks" v-for="block in blocks" :info="block" />
	</div>
	<blast ref="blastBall" :size="blastSize" />
	<div class="title" :style="titleCSS">
		<word ref="titles" v-for="word in words" :word="word.word"
			:width="word.width" :left="word.left" :size="word.size"
		/>
	</div>
	<ladder ref="ladder" />
</template>

<style scoped>
.welcome {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: rgb(10, 10, 30);
	perspective-origin: center;
	perspective: 2000px;
	transform: translateZ(1px);
}
.background {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 0;
	background-position: center;
	background-size: cover;
	opacity: 0.5;
}
.title {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	perspective-origin: center;
	perspective: 1000px;
}
</style>

<script>
import Block from '@/components/company/block.vue';
import Word from '@/components/company/word.vue';
import Blast from '@/components/company/blast.vue';
import Ladder from '@/components/company/ladder.vue';

const OriginPerspective = 2000;
const BlockSize = 100;
const WordWidthToSize = 1.05;
const OrgTitle = "SAIDAO".split('');

export default {
	name: 'Welcome',
	components: {Block, Word, Blast, Ladder},
	data () {
		return {
			stage: 0,
			bgCSS: {},
			titleCSS: {},
			blockSize: 100,
			blocks: [],
			words: [],
			blastSize: 100,
		}
	},
	async mounted () {
		window.addEventListener('resize', this.onResize);

		this.bgCSS = {
			backgroundImage: 'url("' + staticHost + '/images/bg1.jpg")',
			perspective: OriginPerspective / 963 * window.innerHeight,
		};
		this.titleCSS = {
			perspective: OriginPerspective / 963 * window.innerHeight,
		};
		this.generateBlocks();
		this.generateTitle();

		await wait(500);
		for (let title of this.$refs.titles) {
			await wait(500);
			title.fadeIn();
		}

		await wait(1500);
		this.$refs.blastBall.start();

		await wait(800);
		this.$refs.blocks.forEach(b => b.splitOut());
		this.$refs.ladder.show = true;

		await wait(150);
		this.stage = 1;
		this.changeTitle();

		await wait(100);
		this.$emit("entered");
	},
	beforeUnmount () {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize () {
			if (!!this._tmrResize) {
				clearTimeout(this._tmrResize);
			}
			this._tmrResize = setTimeout(this.handleResize, 200);
		},
		handleResize () {
			var span = this.stage === 1 ? 0.2 : 0.5;
			var size = this.stage === 1 ? 0.5 : 0.65;
			var [width, left, fontSize] = this.getWidths(size, span);
			var rate = window.innerHeight / 963;
			this.bgCSS.perspective = OriginPerspective * rate;
			this.titleCSS.perspective = OriginPerspective / 2 * rate;
			this.$refs.titles.forEach((el, i) => {
				el.style.left = (left + width * (1 + span) * i) + 'px';
				el.style.width = (width) + 'px';
				el.style.fontSize = (fontSize) + 'px';
				if (this.stage === 1) {
					el.style.top = Math.round(20 + 30 * rate + fontSize / 2) + 'px';
				}
			});
		},
		getWidths (wRate, sRate) {
			var width = window.innerWidth;
			if (width > 1600) width = 1600;
			var WIDTH = width;
			var height = window.innerHeight;
			if (width / 16 * 9 > height) {
				width = height / 9 * 16;
			}

			var left = Math.round((WIDTH - width * wRate) / 2);
			width = width * wRate / (OrgTitle.length + (OrgTitle.length - 1) * sRate);
			var fontSize = Math.round(width * WordWidthToSize);

			return [width, left, fontSize];
		},
		generateBlocks () {
			var width = window.innerWidth, height = window.innerHeight;
			var size = BlockSize, w = Math.ceil(width / size), h = Math.ceil(height / size);
			this.blockSize = size;
			var offsetX = (w * size - width) / 2, offsetY = (h * size - height) / 2;
			var blocks = [];
			for (let i = 0; i < h; i ++) {
				for (let j = 0; j < w; j ++) {
					let block = {
						size,
						x: j * size - offsetX,
						y: i * size - offsetY,
					};
					blocks.push(block);
				}
			}
			this.blocks = blocks;
		},
		generateTitle () {
			var [width, left, fontSize] = this.getWidths(0.65, 0.5);
			this.blastSize = width;

			OrgTitle.forEach((word, i) => {
				this.words.push({
					word,
					width: Math.round(width),
					left: left + width * 1.5 * i,
					size: fontSize,
				});
			});
		},
		changeTitle () {
			var [width, left, fontSize] = this.getWidths(0.5, 0.2);

			this.$refs.titles.forEach((el, i) => {
				var css = {};
				css.left = left + width * 1.2 * i;
				css.top = Math.round(20 + 30 * window.innerHeight / 963 + fontSize / 2);
				css.width = width;
				css.size = fontSize;
				el.fadeOut(css);
			});
		},
	},
}
</script>