<template>
	<div class="ladder_frame" :style="cssOuter" :inside="show">
		<div class="ladder left">
			<div class="inner" :style="cssInnerLeft"></div>
		</div>
		<div class="ladder right">
			<div class="inner" :style="cssInnerRight"></div>
		</div>
	</div>
</template>

<style scoped>
.ladder_frame {
	position: absolute;
	bottom: -1px;
	left: calc(50% - 80px);
	width: 160px;
	max-height: 0%;
	overflow: hidden;
	transition: all 1500ms ease-in;
}
.ladder_frame[inside="true"] {
	max-height: 100%;
}
.ladder {
	position: absolute;
	bottom: 0px;
	width: 50%;
	height: 100%;
	overflow: hidden;
}
.ladder.left {
	right: 50%;
}
.ladder.right {
	left: 50%;
}
.ladder .inner {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to top, rgba(116, 117, 155, 0.6) 0%, rgba(116, 117, 155, 0) 100%);
	border-image: linear-gradient(to top, rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0.0)) 1;
	transform-origin: 50% 100%;
}
.ladder.left .inner {
	border-left: 1px solid white;
}
.ladder.right .inner {
	border-right: 1px solid white;
}
</style>

<script>
export default {
	name: 'Ladder',
	data () {
		return {
			stage: 0,
			cssOuter: {},
			cssInnerLeft: {},
			cssInnerRight: {},
			show: false,
		}
	},
	mounted () {
		window.addEventListener('resize', this.onResize);

		this.setSize();
	},
	beforeUnmount () {
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		onResize () {
			if (!!this._tmrResize) {
				clearTimeout(this._tmrResize);
			}
			this._tmrResize = setTimeout(this.handleResize, 200);
		},
		handleResize () {
			this.setSize();
		},
		setSize () {
			var size = Math.round(window.innerHeight * 0.7);
			var deg = Math.atan(77 / size) / Math.PI * 180;
			this.cssOuter.height = size + 'px';
			this.cssInnerLeft.transform = `skewX(-${deg}deg)`;
			this.cssInnerRight.transform = `skewX(${deg}deg)`;
		},
	},
}
</script>