<template>
	<div class="outerBlast" :stage="stage" :style="css"></div>
	<div class="innerBlast" :stage="stage" :style="css"></div>
</template>

<style scoped>
.outerBlast, .innerBlast {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	overflow: hidden;
	transition: all 300ms ease-in;
}
.outerBlast {
	background-image: radial-gradient(circle, rgba(255, 147, 41, 0.6) 0%, rgba(255, 213, 45, 0.3) 30%, rgba(255, 213, 45, 0.0) 60%);
}
.outerBlast[stage="0"] {
	transform: translate(-50%, -50%) scale(0.1);
	opacity: 0;
}
.outerBlast[stage="1"] {
	transform: translate(-50%, -50%) scale(0.7);
	opacity: 1;
	transition: all 700ms ease-in;
}
.outerBlast[stage="2"] {
	transform: translate(-50%, -50%) scale(20.0, 1.0);
	opacity: 0.5;
	transition: all 500ms ease-in;
}
.outerBlast[stage="3"] {
	transform: translate(-50%, -50%) scale(50.0, 0.5);
	opacity: 0;
}
.innerBlast {
	background-image: radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.0) 35%, rgba(255, 255, 255, 0.0) 50%);
}
.innerBlast[stage="0"] {
	transform: translate(-50%, -50%) scale(0.01);
	opacity: 0;
}
.innerBlast[stage="1"] {
	transform: translate(-50%, -50%) scale(0.5);
	opacity: 0.4;
	transition: all 700ms ease-in;
}
.innerBlast[stage="2"] {
	transform: translate(-50%, -50%) scale(0.8);
	opacity: 0.1;
	transition: all 500ms ease-in;
}
.innerBlast[stage="3"] {
	transform: translate(-50%, -50%) scale(1.5);
	opacity: 0;
}
</style>

<script>
export default {
	name: 'Blast',
	props: ["size"],
	data () {
		return {
			stage: 0,
			css: {}
		}
	},
	mounted () {
		this.css.width = this.size + 'px';
		this.css.height = this.size + 'px';
	},
	methods: {
		async start () {
			this.stage = 1;
			await wait(300);
			this.stage = 2;
			await wait(700);
			this.stage = 3;
		},
	},
}
</script>