<template>
	<div class="card_container" :style="css" :dir="isLeft?'left':'right'" :stage="stage">
		<slot></slot>
	</div>
</template>

<style>
.card_container {
	position: absolute;
	height: 230px;
	background-color: rgba(226, 225, 228, 0.0);
	color: rgba(186, 204, 217, 1.0);
	transform: translateZ(500px) rotateX(90deg);
	transition: all 2000ms ease-in-out;
	user-select: none;
}
.card_container[dir="left"] {
	right: calc(50% - 10px);
	left: 10px;
	text-align: right;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}
.card_container[dir="right"] {
	left: calc(50% - 10px);
	right: 10px;
	text-align: left;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}
.card_container[stage="0"] {
	pointer-events: none;
}
.card_container[stage="1"] {
	pointer-events: none;
	transform: translateZ(0px) rotateX(0deg);
}
.card_container[stage="2"] {
	transform: translateZ(0px) rotateX(0deg);
	transition: all 500ms ease-in-out;
}
.card_container[stage="3"] {
	transform: translateZ(0px) rotateX(0deg);
	transition: all 2000ms ease-in-out;
}
.card_container[stage="4"] {
	pointer-events: none;
	transform: translateZ(-1000px) rotateX(90deg);
	opacity: 0;
}
.card_container[stage="5"] {
	display: none;
	pointer-events: none;
	transform: translateZ(-1000px) rotateX(90deg);
	opacity: 0;
	transition: none;
}
.card_container[stage="6"] {
	display: none;
	pointer-events: none;
	transform: translateZ(500px) rotateX(90deg);
	opacity: 0;
	transition: none;
}
.card_container[stage="2"]:hover {
	background-color: rgba(226, 225, 228, 0.5);
}
.card_container[stage="2"][dir="left"] {
	right: calc(50% - 10px);
	left: 10px;
}
.card_container[stage="2"][dir="right"] {
	left: calc(50% - 10px);
	right: 10px;
}

.card_container .title {
	position: absolute;
	top: calc(50% - 40px);
	padding: 5px 10px;
	width: 170px;
	height: 80px;
	line-height: 48px;
	font-size: 50px;
	font-weight: bolder;
	text-align: center;
	background-color: rgba(226, 225, 228, 0.1);
	border: 5px solid rgba(226, 225, 228, 0.3);
	border-radius: 15px;
	transition: all 500ms ease-in-out;
	cursor: pointer;
}
.card_container[long="true"] .title {
	width: 350px;
}
.card_container[dir="left"] .title {
	right: 10px;
}
.card_container[dir="right"] .title {
	left: 10px;
}
.card_container[stage="2"] .title {
	box-shadow: inset 1px 1px 1px rgba(22, 22, 25, 0.5), 1px 1px 1px rgba(22, 22, 25, 0.5);
	text-shadow: 1px 1px 1px rgba(22, 22, 25, 0.5);
}
.card_container[stage="2"]:hover .title {
	line-height: 38px;
	background-color: rgba(226, 225, 228, 0.2);
	border: 10px solid rgba(226, 225, 228, 0.5);
	box-shadow: inset 1px 1px 2px rgba(22, 22, 25, 0.9), 1px 1px 2px rgba(22, 22, 25, 0.9);
	text-shadow: 1px 1px 2px rgba(22, 22, 25, 0.9);
}

.card_container .list {
	display: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
	text-shadow: 1px 1px 2px rgba(22, 22, 25, 0.9);
	transition: all 500ms ease-in-out;
}
.card_container[stage="2"] .list {
	display: block;
	opacity: 0;
}
.card_container[stage="2"]:hover .list {
	opacity: 1;
}
.card_container[dir="left"] .list {
	right: 90px;
}
.card_container[dir="right"] .list {
	left: 90px;
}
.card_container[dir="left"]:hover .list {
	right: 200px;
}
.card_container[dir="right"]:hover .list {
	left: 200px;
}
.card_container .list .line {
	position: relative;
	padding-top: 2px;
	padding-bottom: 2px;
}
.card_container .list .line span.desc {
	font-size: 0.8em;
	font-weight: lighter;
}
.card_container[stage="2"] .list .line span.desc {
	text-shadow: none;
}
.card_container[dir="left"] .list .line::after {
	content: "\2022";
	position: absolute;
	top: 5px;
	right: -10px;
}
.card_container[dir="right"] .list .line::before {
	content: "\2022";
	position: absolute;
	top: 5px;
	left: -10px;
}

.card_container .logos {
	position: absolute;
	height: 230px;
	max-width: 0%;
	overflow: hidden;
	background-color: rgba(226, 225, 228, 0.0);
	transition: all 500ms ease-in-out;
}
.card_container[dir="left"] .logos {
	left: 100%;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}
.card_container[dir="right"] .logos {
	right: 100%;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}
.card_container[stage="2"]:hover .logos {
	max-width: 100%;
	background-color: rgba(226, 225, 228, 0.5);
}
.card_container .logos .inner_frame {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	height: 100%;
}
.card_container[dir="left"] .logos .inner_frame {
	padding-left: 10px;
	justify-content: flex-start;
}
.card_container[dir="right"] .logos .inner_frame {
	padding-right: 10px;
	justify-content: flex-end;
}
.card_container .logos .logo {
	margin-top: 10px;
	margin-bottom: 10px;
}
.card_container .logos img {
	height: 60px;
}
.card_container .logos img.small {
	height: 40px;
}
.card_container[dir="left"] .logos img {
	margin-right: 60px;
}
.card_container[dir="right"] .logos img {
	margin-left: 60px;
}
.card_container .logos div.logo span {
	display: block;
}
.card_container[dir="left"] .logos div.logo span {
	text-align: left;
}
.card_container[dir="right"] .logos div.logo span {
	text-align: right;
}
.card_container .logos div.logo span.cap {
	font-size: 36px;
	text-shadow: 1px 1px 2px rgba(22, 22, 25, 0.9);
}
.card_container .logos div.logo span.desc {
	margin-top: 10px;
	font-size: 20px;
	text-shadow: 1px 1px 1px rgba(22, 22, 25, 0.5);
}
</style>

<script>
export default {
	name: 'Card',
	props: ["dir"],
	data () {
		return {
			isLeft: true,
			stage: 0,
			css: {},
		}
	},
	mounted () {
		this.isLeft = this.dir;

		var lines = this.$el.querySelectorAll('div.line');
		for (let line of lines) {
			let inner = line.innerHTML;
			if (inner.indexOf('\n') >= 0) {
				inner = inner.split(/[\r\n]+/).filter(i => !!i);
				let html = '<span class="cap">' + inner[0] + '</span><br>';
				inner.splice(0, 1);
				inner = inner.join(' ');
				html = html + '<span class="desc">' + inner + '</span>';
				line.innerHTML = html;
			}
		}

		lines = this.$el.querySelectorAll('div.logo');
		for (let line of lines) {
			let inner = line.innerHTML;
			if (inner.indexOf('\n') >= 0) {
				inner = inner.split(/[\r\n]+/).filter(i => !!i);
				let html = '<span class="cap">' + inner[0] + '</span><br>';
				inner.splice(0, 1);
				inner = inner.join(' ');
				html = html + '<span class="desc">' + inner + '</span>';
				line.innerHTML = html;
			}
		}
	},
	methods: {
		setPosition (bottom, offset, isLeft) {
			this.css.bottom = bottom + 'px';
			if (isLeft) {
				this.css.marginRight = offset + 'px';
			}
			else {
				this.css.marginLeft = offset + 'px';
			}
			this.isLeft = isLeft;
		},
		async enterStage () {
			this.stage = 1;
			await wait(2000);
			this.stage = 2;
		},
		async exitStage () {
			this.stage = 3;
			
			await wait(0);
			this.stage = 4;

			await wait(2000);
			this.stage = 5;
			this.$el.style.display = 'none';

			await wait(0);
			this.stage = 6;

			await wait(0);
			this.stage = 0;
			await wait (100);
			this.$el.style.display = 'block';
		}
	},
}
</script>